import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';

function ApolloRedSea() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure">
      <Helmet>
        <title>Apollo x Saudi Arabia</title>
        <meta property="og:title" content="Apollo x Saudi Arabia" />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vT5-YwiMgZX2UcwfMQ7Fy1uSBvDyzPjliI-zV8vZCEkZz9nue5j0DGQOVRsm4iMM0BZZVfulg96x4Il/embed?start=false&loop=false&delayms=3000"
          frameBorder="0"
          width="960"
          height="569"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default ApolloRedSea;