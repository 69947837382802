import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloPremierLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';

function PremierBrochure() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure">
      <Helmet>
        <title>Apollo Premier Brochure</title>
        <meta property="og:title" content="Apollo Premier Brochure" />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link premier" href="/">
          <img src={ApolloPremierLogo} />
        </a>
      </div>
      <div>
        <iframe
          src="https://indd.adobe.com/view/22b5d055-8ccb-4552-91c8-45f9cc7d64c3?startpage=1&allowFullscreen=true"
          width="950px"
          height="700px"
          frameBorder="0"
          allowFullScreen
        ></iframe>

        {/* <iframe
          src="https://indd.adobe.com/embed/58d4be55-12f6-4f77-b2fa-c3fe8dac6cc7?startpage=1&allowFullscreen=true"
          width="1000px"
          height="580px"
          frameBorder="0"
          allowFullScreen
        ></iframe> */}
      </div>
    </div>
  )
}

export default PremierBrochure;