import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';

function InvestingInDigitalArtMobileBrochure() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure">
      <Helmet>
        <title>Apollo Marketplace Mobile Brochure</title>
        <meta property="og:title" content="First Touch Mobile Brochure" />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>
        <iframe
          src="https://indd.adobe.com/view/0d6e1b63-ff47-4e68-a20a-c474c225b259?startpage=1&allowFullscreen=true"
          width="480px"
          height="700px"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default InvestingInDigitalArtMobileBrochure;