import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';
import { IgniteYourSensesVideo2 } from '../../videos';

function IgniteYourSenses() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure video">
      <Helmet>
        <title>Ignite Your Senses | Apollo</title>
        <meta property="og:title" content="Ignite Your Senses | Apollo" />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>
        <video autoPlay controls src={IgniteYourSensesVideo2}></video>
      </div>
    </div>
  )
}

export default IgniteYourSenses;