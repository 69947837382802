import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';

interface ApolloPresentationProps {
  name: string,
  presentationUrl: string
}

function ApolloPresentation(props: ApolloPresentationProps) {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const { name, presentationUrl } = props;

  return (
    <div className="full-brochure">
      <Helmet>
        <title>{name}</title>
        <meta property="og:title" content={name} />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>
        <iframe src={presentationUrl}
          frameBorder="0"
          width="960"
          height="569"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default ApolloPresentation;