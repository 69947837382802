import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';

function ApolloAAMay2022() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure">
      <Helmet>
        <title>Apollo x AA May 2022</title>
        <meta property="og:title" content="Apollo x AA May 2022" />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vS3VU3nd7PtWrrwFryxNwaLWisIxY_UE5FfUV0we9V7xSJPlIpLMT8koyMFpu-Kvzzn5qptOi0eff-I/embed?start=false&loop=false&delayms=3000"
          frameBorder="0"
          width="960"
          height="569"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default ApolloAAMay2022;