import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';

function Bulletin3() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure">
      <Helmet>
        <title>Apollo Bulletin #3</title>
        <meta property="og:title" content="Apollo Bulletin #3" />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>
        <iframe
          src="https://docs.google.com/presentation/d/e/2PACX-1vRLnlzTCmAi6QO13eWngDW9680YYX1XoruGw_rAkU_OrUpEAtJgX5Gnph7ukHGnA0y_wZv1VJoZ5zqn/embed?start=false&loop=false&delayms=30000"
          frameBorder="0"
          width="1224"
          height="1613"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default Bulletin3;