import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';

function ArtInTheSkyIFEPromo() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure">
      <Helmet>
        <title>Apollo x AA Art in the Sky IFE Promo</title>
        <meta property="og:title" content="Apollo x AA Art in the Sky IFE Promo" />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>
        <iframe title="vimeo-player"
          src="https://player.vimeo.com/video/698809792?h=b5319785e1"
          width="640"
          height="360"
          frameBorder="0"
          allowFullScreen>
        </iframe>
      </div>
    </div>
  )
}

export default ArtInTheSkyIFEPromo;