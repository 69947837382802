import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';

function ApolloGooglePartners() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure">
      <Helmet>
        <title>Apollo & Google Partners</title>
        <meta property="og:title" content="Apollo & Google Partners" />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vQGbUwTbz3Kc6lgRmCf9iPB9Gzi6HTkVJ6D-TWzz_UJ4QO2zpRk5OXZpeINOCyl3sSRkyCALlNEs1-D/embed?start=false&loop=false&delayms=3000"
          frameBorder="0"
          width="960"
          height="569"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default ApolloGooglePartners;