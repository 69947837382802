import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';

function OneSheetBrochure() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure">
      <Helmet>
        <title>Apollo One Sheet</title>
        <meta property="og:title" content="Meet Apollo Brochure" />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>
        <iframe
          src="https://indd.adobe.com/view/3db8ddfc-2646-4d4b-ae07-cfcb68654735?startpage=1&allowFullscreen=true"
          width="950px"
          height="700px"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default OneSheetBrochure;