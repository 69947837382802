import { 
  ActionType, 
  SET_TOP_SECTION, 
  SET_IS_MOBILE, 
  PLAY_AUDIO_LOOP, 
  PAUSE_AUDIO_LOOP, 
  SHOW_INSTALLATION_POPUP,
  SHOW_ARTIST_POPUP,
} from './types'

import { Installation, Artist } from '../types/ContentTypes';

export const setTopSection = (payload: string): ActionType => {
  return {
    type: SET_TOP_SECTION,
    payload,
  };
};

export const setIsMobile = (payload: boolean): ActionType => {
  return {
    type: SET_IS_MOBILE,
    payload,
  };
};

export const playAudioLoop = (): ActionType => {
  return {
    type: PLAY_AUDIO_LOOP,
  };
};

export const pauseAudioLoop = (): ActionType => {
  return {
    type: PAUSE_AUDIO_LOOP,
  };
};

export const showInstallationPopup = (payload: Installation | null): ActionType => {
  return {
    type: SHOW_INSTALLATION_POPUP,
    payload: payload,
  };
};

export const showArtistPopup = (payload: Artist | null): ActionType => {
  return {
    type: SHOW_ARTIST_POPUP,
    payload: payload,
  };
};