import React, { Component } from 'react';
import Redux from 'redux';
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import Home from '../Home/Home';
import Login from '../Login/Login';
import ModeService from '../../services/ModeService';
import { connect } from 'react-redux';
import './App.scss';
import { AppState } from '../../types';
import { ActionType } from '../../actions/types';
import { setIsMobile } from '../../actions';
import Submission from '../Submission/Submission';
import SubmissionComplete from '../SubmissionComplete/SubmissionComplete';
import ContactUsComplete from '../ContactUsComplete/ContactUsComplete';
import ContactUs from '../ContactUs/ContactUs';
import Brochure from '../Brochure/Brochure';
import ArtistsFlipbook from '../ArtistsFlipbook/ArtistsFlipbook';
import ReactGA from 'react-ga';
import Content from '../Content/Content';
import MobileBrochure from '../MobileBrochure/MobileBrochure';
import DrebinBrochure from '../DrebinBrochure/DrebinBrochure';
import PremierBrochure from '../PremierBrochure/PremierBrochure';
import AppBrochure from '../AppBrochure/AppBrochure';
import AppMobileBrochure from '../AppMobileBrochure/AppMobileBrochure';
import MarketplaceBrochure from '../MarketplaceBrochure/MarketplaceBrochure';
import { BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';
import MeetApolloBrochure from '../MeetApolloBrochure/MeetApolloBrochure';
import OneSheetBrochure from '../OneSheetBrochure/OneSheetBrochure';
import PremierMobileBrochure from '../PremierMobileBrochure/PremierMobileBrochure';
import MarketplaceMobileBrochure from '../MarketplaceMobileBrochure/MarketplaceMobileBrochure';
import InvestingInDigitalArtBrochure from '../InvestingInDigitalArtBrochure/InvestingInDigitalArtBrochure';
import IgniteYourSenses from '../IgniteYourSenses/IgniteYourSenses';
import IgniteYourSensesMobile from '../IgniteYourSensesMobile/IgniteYourSensesMobile';
import DiversityBrochure from '../DiversityBrochure/DiversityBrochure';
import HighLevelArtistInvitation from '../HighLevelArtistInvitation/HighLevelArtistInvitation';
import InvestingInDigitalArtMobileBrochure from '../InvestingInDigitalArtMobileBrochure/InvestingInDigitalArtMobileBrochure';
import LaunchBulletin2 from '../LaunchBulletin2/LaunchBulletin2';
import Jobs from '../Jobs/Jobs';
import IntroToApollo from '../IntroToApollo/IntroToApollo';
import PremierHome from '../PremierHome/PremierHome';
import OurArtists from '../OurArtists/OurArtists';
import Bulletin3 from '../Bulletin3/Bulletin3';
import ApolloMarriottOwners from '../ApolloMarriottOwners/ApolloMarriottOwners';
import ApolloNetJets from '../ApolloNetJets/ApolloNetJets';
import ApolloMarriott from '../ApolloMarriot/ApolloMarriott';
import AVPartnerProgramIntro from '../AVPartnerProgramIntro/AVPartnerProgramIntro';
import AmadeoModigliani from '../AmadeoModigliani/AmadeoModigliani';
import ArtInTheSkyIFEPromo from '../ArtInTheSkyIFEPromo/ArtInTheSkyIFEPromo';
import ApolloBron from '../ApolloBron/ApolloBron';
import ApolloAACampaign from '../ApolloAACampaign/ApolloAACampaign';
import ApolloUniguestSubscriptionOpts from '../ApolloUniguestSubscriptionOpts/ApolloUniguestSubscriptionOpts';
import ApolloStRegisCostaMujeres from '../ApolloStRegisCostaMujeres/ApolloStRegisCostaMujeres';
import ApolloNorthernTrust from '../ApolloNorthernTrust/ApolloNorthernTrust';
import PremierOverviewForAVPartners from '../PremierOverviewForAVPartners/PremierOverviewForAVPartners';
import ApolloEsmePartnership from '../ApolloEsmePartnership/ApolloEsmePartnership';
import ApolloAAMay2022 from '../ApolloAAMay2022/ApolloAAMay2022';
import ApolloInvestorDeck from '../ApolloInvestorDeck/ApolloInvestorDeck';
import ApolloIntroductionClassics from '../ApolloIntroductionClassics/ApolloIntroductionClassics';
import ApolloIntroductionContemporary from '../ApolloIntroductionContemporary/ApolloIntroductionContemporary';
import ApolloAAJune2022 from '../ApolloAAJune2022/ApolloAAJune2022';
import ApolloUniguest from '../ApolloUniguest/ApolloUniguest';
import MeetApollo2Brochure from '../MeetApollo2Brochure/MeetApollo2Brochure';
import ApolloSordoMadaleno from '../ApolloSordoMadaleno/ApolloSordoMadaleno';
import ApolloAALoungesJuly from '../ApolloAALoungesJuly/ApolloAALoungesJuly';
import ApolloChristies from '../ApolloChristies/ApolloChristies';
import ApolloNovaSkyStories from '../ApolloNovaSkyStories/ApolloNovaSkyStories';
import ApolloWerkhaus from '../ApolloWerkhaus/ApolloWerkhaus';
import ApolloRedSea from '../ApolloRedSea/ApolloRedSea';
import ApolloRaydiant from '../ApolloRaydiant/ApolloRaydiant';
import ApolloAlmare from '../ApolloAlmare/ApolloAlmare';
import ApolloCompetetiveAnalysis from '../ApolloCompetetiveAnalysis/ApolloCompetetiveAnalysis';
import ApolloHospitality from '../ApolloHospitality/ApolloHospitality';
import ApolloRosewood from '../ApolloRosewood/ApolloRosewood';
import ApolloLG from '../ApolloLG/ApolloLG';
import ApolloIntroductionSeptember2022 from '../ApolloIntroductionSeptember2022/ApolloIntroductionSeptember2022';
import ApolloJoshDavidson from '../ApolloJoshDavidson/ApolloJoshDavidson';
import ApolloMarriotSep2022 from '../ApolloMarriotSep2022/ApolloMarriotSep2022';
import ApolloProductAndStrategy from '../ApolloProductAndStrategy/ApolloProductAndStrategy';
import ApolloInvestorDeck2022 from '../ApolloInvestorDeck2022/ApolloInvestorDeck2022';
import ApolloDevelopers from '../ApolloDevelopers/ApolloDevelopers';
import ApolloArtistsAndGalleries from '../ApolloArtistsAndGalleries/ApolloArtistsAndGalleries';
import ApolloSaudiVision2030 from '../ApolloSaudiVision2030/ApolloSaudiVision2030';
import ApolloMarriottHQ from '../ApolloMarriottHQ/ApolloMarriottHQ';
import ApolloHospitalityOct2022 from '../ApolloHospitalityOct2022/ApolloHospitalityOct2022';
import ApolloSaudiVision2030Nov from '../ApolloSaudiVision2030Nov/ApolloSaudiVision2030Nov';
import ApolloArtCollectors from '../ApolloArtCollectors/ApolloArtCollectors';
import ApolloDeutscheBahn from '../ApolloDeutscheBahn/ApolloDeutscheBahn';
import ApolloNorthernTrustExpo from '../ApolloNorthernTrustExpo/ApolloNorthernTrustExpo';
import ApolloBucherer from '../ApolloBucherer/ApolloBucherer';
import ApolloSammlungPhilara from '../ApolloSammlungPhilara/ApolloSammlungPhilara';
import ApolloMcKinsey from '../ApolloMcKinsey/ApolloMcKinsey';
import ApolloUptonProperties from '../ApolloUptonProperties/ApolloUptonProperties';
import ApolloArtists from '../ApolloArtists/ApolloArtists';
import ApolloGalleriesBrochure from '../ApolloGalleriesBrochure/ApolloGalleriesBrochure';
import ApolloArtistsBrochure from '../ApolloArtistsBrochure/ApolloArtistsBrochure';
import ApolloGalleries from '../ApolloGalleries/ApolloGalleries';
import ApolloArtistsDecember from '../ApolloArtistsDecember/ApolloArtistsDecember';
import ApolloGuesthouseHotel from '../ApolloGuesthouseHotel/ApolloGuesthouseHotel';
import ApolloExpo from '../ApolloExpo/ApolloExpo';
import Apollo2LG from '../Apollo2LG/Apollo2LG';
import ApolloFrieze from '../ApolloFrieze/ApolloFrieze';
import Apollo4731MyerwoodLn from '../Apollo4731MyerwoodLn/Apollo4731MyerwoodLn';
import ApolloPilati from '../ApolloPilati/ApolloPilati';
import ApolloRosewoodV2 from '../ApolloRosewoodV2/ApolloRosewoodV2';
import ApolloDermalogica from '../ApolloDermalogica/ApolloDermalogica';
import ApolloEXPOChicagoLG from '../ApolloEXPOChicagoLG/ApolloEXPOChicagoLG';
import ApolloDevelopers2023 from '../ApolloDevelopers2023/ApolloDevelopers2023';
import ApolloLG2023 from '../ApolloLG2023/ApolloLG2023';
import ApolloGooglePartners from '../ApolloGooglePartners/ApolloGooglePartners';
import ApolloGalleriesJan2023 from '../ApolloGalleriesJan2023/ApolloGalleriesJan2023';
import MeetApolloJan2023 from '../MeetApolloJan2023/MeetApolloJan2023';
import ApolloBeyondHotel from '../ApolloBeyondHotel/ApolloBeyondHotel';
import ApolloUAE from '../ApolloUAE/ApolloUAE';
import ApolloEmirates from '../ApolloEmirates/ApolloEmirates';
import ApolloDXB from '../ApolloDXB/ApolloDXB';
import ApolloArtistsFeb2023 from '../ApolloArtistsFeb2023/ApolloArtistsFeb2023';
import ApolloPresentation from '../ApolloPresentation/ApolloPresentation';

interface AppRoute {
  path: string,
  presentationUrl: string,
  name: string,
}

interface DispatchProps {
  setIsMobile(isMobile: boolean): void,
}

interface AppComponentProps extends DispatchProps {
  isMobile: boolean,
}

interface AppComponentState {
  loading: boolean,
  routes: Array<AppRoute>
}

class App extends Component<AppComponentProps, AppComponentState> {

  state = {
    loading: true,
    routes: new Array<AppRoute>()
  }

  componentDidMount() {
    ReactGA.initialize('UA-180422933-1');

    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize();

    fetch('/routes.json', {
      method: 'get',
    }).then(res => res.json())
      .then(res => {
        this.setState({
          loading: false,
          routes: res
        });
      });
  }

  onWindowResize = () => {
    const isMobile = window.innerWidth <= 768;
    if (isMobile !== this.props.isMobile) {
      this.props.setIsMobile(isMobile);
    }
  }

  render() {
    const routes = this.state.routes;

    return (
      <div className="site-container">
        <Helmet>
          <title>apollo</title>
          <meta property="og:title" content="apollo" />
          <meta property="og:image" content={BrochureThumbnail} />
        </Helmet>
        <React.Fragment>
          <Router>
            <Route exact path="/brochure">
              <Redirect to="/brochure/first-touch" />
            </Route>
            <Route exact path="/mobile-brochure">
              <Redirect to="/brochure/first-touch-mobile" />
            </Route>
            <Route exact path="/brochure/first-touch" component={Brochure} />
            <Route exact path="/brochure/first-touch-mobile" component={MobileBrochure} />


            <Route exact path="/brochure/david-drebin" component={DrebinBrochure} />

            <Route exact path="/brochure/premier" component={PremierBrochure} />
            <Route exact path="/brochure/premier-mobile" component={PremierMobileBrochure} />

            <Route exact path="/brochure/app" component={AppBrochure} />
            <Route exact path="/brochure/app-mobile" component={AppMobileBrochure} />

            <Route exact path="/brochure/marketplace" component={MarketplaceBrochure} />
            <Route exact path="/brochure/marketplace-mobile" component={MarketplaceMobileBrochure} />

            <Route exact path="/brochure/meet-apollo" component={MeetApolloBrochure} />
            <Route exact path="/brochure/apollo-introduction" component={MeetApollo2Brochure} />

            <Route exact path="/present/meet-apollo-jan-2023" component={MeetApolloJan2023} />

            <Route exact path="/present/apollo-beyond-hotel" component={ApolloBeyondHotel} />

            <Route exact path="/brochure/one-sheet" component={OneSheetBrochure} />

            <Route exact path="/brochure/investing-in-digital-art-101" component={InvestingInDigitalArtBrochure} />
            <Route exact path="/brochure/investing-in-digital-art-101-mobile" component={InvestingInDigitalArtMobileBrochure} />

            <Route exact path="/brochure/diversity" component={DiversityBrochure} />

            <Route exact path="/brochure/high-level-artist-invitation" component={HighLevelArtistInvitation} />

            <Route exact path="/brochure/av-partner-program-intro" component={AVPartnerProgramIntro} />
            <Route exact path="/brochure/premier-overview-for-av-partners" component={PremierOverviewForAVPartners} />

            <Route exact path="/brochure/ignite-your-senses" component={IgniteYourSenses} />
            <Route exact path="/brochure/ignite-your-senses-mobile" component={IgniteYourSensesMobile} />

            <Route exact path="/present/apollo-marriott" component={ApolloMarriott} />
            <Route exact path="/present/apollo-marriott-owners" component={ApolloMarriottOwners} />
            <Route exact path="/present/apollo-marriott-september-2022" component={ApolloMarriotSep2022} />
            <Route exact path="/present/apollo-marriott-hq" component={ApolloMarriottHQ} />

            <Route exact path="/present/apollo-esme-partnership" component={ApolloEsmePartnership} />

            <Route exact path="/present/amadeo-modigliani" component={AmadeoModigliani} />

            <Route exact path="/present/apollo-bron" component={ApolloBron} />

            <Route exact path="/present/apollo-aa-campaign" component={ApolloAACampaign} />
            <Route exact path="/present/apollo-aa-may-2022" component={ApolloAAMay2022} />
            <Route exact path="/present/apollo-aa-june-2022" component={ApolloAAJune2022} />
            <Route exact path="/present/apollo-aa-lounges-july" component={ApolloAALoungesJuly} />

            <Route exact path="/present/apollo-netjets" component={ApolloNetJets} />

            <Route exact path="/present/apollo-northern-trust" component={ApolloNorthernTrust} />

            <Route exact path="/present/apollo-st-regis-costa-mujeres" component={ApolloStRegisCostaMujeres} />

            <Route exact path="/present/apollo-uniguest" component={ApolloUniguest} />
            <Route exact path="/present/apollo-uniguest-partnership-subscription-options" component={ApolloUniguestSubscriptionOpts} />

            <Route exact path="/present/apollo-investors-2023">
              <Redirect to='/present/apollo-investors' />
            </Route>
            <Route exact path="/present/apollo-investors-2022">
              <Redirect to='/present/apollo-investors' />
            </Route>
            <Route exact path="/present/apollo-investor">
              <Redirect to='/present/apollo-investors' />
            </Route>
            <Route exact path="/present/apollo-investors" component={ApolloInvestorDeck2022} />

            <Route exact path="/present/apollo-developers" component={ApolloDevelopers} />
            <Route exact path="/present/apollo-developers-2023" component={ApolloDevelopers2023} />

            <Route exact path="/present/apollo-artists" component={ApolloArtists} />
            <Route exact path="/present/apollo-artists-and-galleries" component={ApolloArtistsAndGalleries} />
            <Route exact path="/present/apollo-art-collectors" component={ApolloArtCollectors} />

            <Route exact path="/present/apollo-artists-december" component={ApolloArtistsDecember} />
            <Route exact path="/present/apollo-artists-feb-2023" component={ApolloArtistsFeb2023} />
            <Route exact path="/present/apollo-galleries" component={ApolloGalleries} />
            <Route exact path="/present/apollo-galleries-jan-2023" component={ApolloGalleriesJan2023} />
            <Route exact path="/brochure/apollo-artists" component={ApolloArtistsBrochure} />
            <Route exact path="/brochure/apollo-galleries" component={ApolloGalleriesBrochure} />

            <Route exact path="/present/apollo-guesthouse-hotel" component={ApolloGuesthouseHotel} />

            <Route exact path="/present/apollo-and-frieze" component={ApolloFrieze} />

            <Route exact path="/present/apollo-and-pilati" component={ApolloPilati} />

            <Route exact path="/present/apollo-and-4731-myerwood-ln" component={Apollo4731MyerwoodLn} />

            <Route exact path="/present/apollo-expo" component={ApolloExpo} />

            <Route exact path="/present/apollo-2-lg" component={Apollo2LG} />

            <Route exact path="/present/apollo-and-chicago-expo-lg" component={ApolloEXPOChicagoLG} />

            <Route exact path="/present/apollo-deutsche-bahn" component={ApolloDeutscheBahn} />

            <Route exact path="/present/apollo-upton-properties" component={ApolloUptonProperties} />

            <Route exact path="/present/apollo-bucherer" component={ApolloBucherer} />

            <Route exact path="/present/apollo-sammlung-philara" component={ApolloSammlungPhilara} />

            <Route exact path="/present/apollo-mckinsey" component={ApolloMcKinsey} />

            <Route exact path="/present/apollo-northern-trust-expo" component={ApolloNorthernTrustExpo} />

            <Route exact path="/present/apollo-saudi-vision-2030" component={ApolloSaudiVision2030} />
            <Route exact path="/present/apollo-saudi-vision-2030-nov" component={ApolloSaudiVision2030Nov} />

            <Route exact path="/present/apollo-introduction-classics" component={ApolloIntroductionClassics} />
            <Route exact path="/present/apollo-introduction-contemporary" component={ApolloIntroductionContemporary} />

            <Route exact path="/present/apollo-sordo-madaleno" component={ApolloSordoMadaleno} />

            <Route exact path="/present/apollo-christies" component={ApolloChristies} />

            <Route exact path="/present/apollo-nova-sky-stories" component={ApolloNovaSkyStories} />

            <Route exact path="/present/apollo-werkhaus" component={ApolloWerkhaus} />

            <Route exact path="/present/apollo-josh-davidson" component={ApolloJoshDavidson} />

            <Route exact path="/present/apollo-saudi-arabia" component={ApolloRedSea} />

            <Route exact path="/present/apollo-uae-feb-2023" component={ApolloUAE} />

            <Route exact path="/present/apollo-and-emirates" component={ApolloEmirates} />

            <Route exact path="/present/apollo-dxb" component={ApolloDXB} />

            <Route exact path="/present/apollo-competetive-analysis" component={ApolloCompetetiveAnalysis} />

            <Route exact path="/present/apollo-raydiant" component={ApolloRaydiant} />

            <Route exact path="/present/apollo-almare" component={ApolloAlmare} />

            <Route exact path="/present/apollo-lg" component={ApolloLG} />
            <Route exact path="/present/apollo-lg-2023" component={ApolloLG2023} />

            <Route exact path="/present/apollo-and-google-partners" component={ApolloGooglePartners} />

            <Route exact path="/present/apollo-introduction-september-2022" component={ApolloIntroductionSeptember2022} />

            <Route exact path="/present/apollo-product-and-strategy" component={ApolloProductAndStrategy} />

            <Route exact path="/present/apollo-hospitality" component={ApolloHospitalityOct2022} />
            <Route exact path="/present/apollo-hospitality-oct-2022">
              <Redirect to='/present/apollo-hospitality' />
            </Route>

            <Route exact path="/present/apollo-dermalogica" component={ApolloDermalogica} />

            <Route exact path="/present/apollo-rosewood" component={ApolloRosewood} />
            <Route exact path="/present/apollo-rosewood-v2" component={ApolloRosewoodV2} />

            <Route exact path="/video/art-in-the-sky-ife-promo" component={ArtInTheSkyIFEPromo} />

            <Route exact path="/Bulletin/apollo-launching-bulletin-2" component={LaunchBulletin2} />
            <Route exact path="/Bulletin/apollo-bulletin-3" component={Bulletin3} />

            {routes.map((route, i) => {
              return (
                <Route key={i} exact path={route.path}>
                  <ApolloPresentation name={route.name} presentationUrl={route.presentationUrl} />
                </Route>
              );
            })}

          </Router>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isMobile: state.isMobile,
});

const mapDispatchToProps = (dispatch: Redux.Dispatch<ActionType>): DispatchProps => {
  return {
    setIsMobile: (isMobile: boolean) => dispatch(setIsMobile(isMobile)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
