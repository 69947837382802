import { AppState } from '../types';
import { 
  ActionType,
  SET_TOP_SECTION,
  SET_IS_MOBILE,
  PLAY_AUDIO_LOOP,
  PAUSE_AUDIO_LOOP,
  SHOW_INSTALLATION_POPUP,
  SHOW_ARTIST_POPUP
} from '../actions/types';

const initialState: AppState = { 
  topSection: null,
  isMobile: false,
  audioLoopShouldPlay: true,
  popupInstallation: null,
  popupArtist: null,
};

export default (state = initialState, action: ActionType): AppState => {
  switch (action.type) {
    case SET_TOP_SECTION:
      return {
        ...state,
        topSection: action.payload,
      };
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      }
    case PLAY_AUDIO_LOOP:
      return {
        ...state,
        audioLoopShouldPlay: true,
      }
    case PAUSE_AUDIO_LOOP:
      return {
        ...state,
        audioLoopShouldPlay: false,
      }
    case SHOW_INSTALLATION_POPUP:
      return {
        ...state,
        popupInstallation: action.payload,
      }
    case SHOW_ARTIST_POPUP:
      return {
        ...state,
        popupArtist: action.payload,
      }
    default:
      return state;
  }
}