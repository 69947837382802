import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo, BrochureThumbnail } from '../../images';
import { Helmet } from 'react-helmet';

function ApolloAACampaign() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure">
      <Helmet>
        <title>Apollo x AA Campaign</title>
        <meta property="og:title" content="Apollo x AA Campaign" />
        <meta property="og:image" content={BrochureThumbnail} />
      </Helmet>
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSH9rANdJJbMYI9uAeUKpJvNauX3vIxXI0vL9BNCiRmnVos00oO43u0QsZ2h_HRYbOlg6rw6TOgjltW/embed?start=false&loop=false&delayms=3000"
          frameBorder="0"
          width="960"
          height="569"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  )
}

export default ApolloAACampaign;