import { Installation, Artist } from "../types/ContentTypes";

export const SET_TOP_SECTION = 'SET_TOP_SECTION';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const PLAY_AUDIO_LOOP = 'PLAY_AUDIO_LOOP';
export const PAUSE_AUDIO_LOOP = 'PAUSE_AUDIO_LOOP';
export const SHOW_INSTALLATION_POPUP = 'SHOW_INSTALLATION_POPUP';
export const SHOW_ARTIST_POPUP = 'SHOW_ARTIST_POPUP';


interface SetTopSectionAction {
  type: typeof SET_TOP_SECTION,
  payload: string,
}

interface SetIsMobile {
  type: typeof SET_IS_MOBILE,
  payload: boolean,
}

interface PlayAudioLoop {
  type: typeof PLAY_AUDIO_LOOP,
}

interface PauseAudioLoop {
  type: typeof PAUSE_AUDIO_LOOP,
}

interface ShowInstallationPopup {
  type: typeof SHOW_INSTALLATION_POPUP,
  payload: Installation | null,
}

interface ShowArtistPopup {
  type: typeof SHOW_ARTIST_POPUP,
  payload: Artist | null,
}

export type ActionType = SetTopSectionAction | SetIsMobile | PlayAudioLoop | PauseAudioLoop | ShowInstallationPopup | ShowArtistPopup;