import React from 'react';
import ReactGA from 'react-ga';
import '../Brochure/Brochure.scss';
import { ApolloLogo } from '../../images';

function DrebinBrochure() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className="full-brochure">
      <div className="back-link-container">
        <a className="back-link" href="/">
          <img src={ApolloLogo} />
        </a>
      </div>
      <div>

        <iframe
          src="https://indd.adobe.com/view/e45737ce-71df-4890-ab1f-e65fa7078378?startpage=1&allowFullscreen=true"
          width="1100px"
          height="700px"
          frameBorder="0"
          allowFullScreen
        ></iframe>

      </div>
    </div>
  )
}

export default DrebinBrochure;